<template>
  <!-- App.vue -->
  <v-app>
    <SystemBar v-if="!$vuetify.breakpoint.mobile" />
    <Drawer />
    <AppBar />
    <!-- Sizes your content based upon application components -->
    <router-view></router-view>

    <Footer />
  </v-app>
</template>

<script>
export default {
  name: "App",

  components: {
    AppBar: () => import("@/components/AppBar.vue"),
    Drawer: () => import("@/components/Drawer/Drawer.vue"),
    SystemBar: () => import("@/components/SystemBar.vue"),
    Footer: () => import("@/components/Footer.vue")
  },

  data: () => ({
    //
  })
};
</script>
<style>
::-webkit-scrollbar {
  width: 10px;
  background-color: #f5f5f5;
}
::-webkit-scrollbar-track {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: #000000;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  /* background-image: -webkit-linear-gradient(90deg,rgba(255, 255, 255, .2) 25%,transparent 25%,transparent 50%,rgba(255, 255, 255, .2) 50%,rgba(255, 255, 255, .2) 75%,transparent 75%,transparent) */
}
h1 {
  font-size: 2rem;
}
.izquierdaDerecha {
  animation-name: slideLeftToRight;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.derechaIzquierda {
  animation-name: slideRightToLeft;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.bottomToTop {
  animation-name: slideBottomToTop;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
.topToBottom {
  animation-name: slideTopToBottom;
  animation-duration: 2s;
  animation-fill-mode: forwards;
}
@keyframes slideLeftToRight {
  from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideRightToLeft {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
    opacity: 1;
  }
}
@keyframes slideTopToBottom {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
@keyframes slideBottomToTop {
  from {
    transform: translateY(100px);
  }
  to {
    transform: translateY(0%);
    opacity: 1;
  }
}
.oculto {
  opacity: 0;
}
</style>
